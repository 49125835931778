.bg-top-img::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: url(../../images/heroimg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: -1;
}
.sm-show{
    display: none !important;
}
.bg-top-img {
    position: relative;
}
.bg-top-img::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 0;
    height: 100%;
    background: linear-gradient(90.63deg,rgb(15 2 2) 4.82%, rgb(16 7 9 / 50%) 16.33%);
    transition: all 0.3s ease;
}
.top-swiper .swiper-button-prev,
.top-swiper .swiper-button-next {
    font-size: 35px;
    color: #fff;
    margin-left: 10px;
}
.top-swiper .swiper-button-prev::after {
    content: "";
    z-index: 1000;
    position: absolute;
    height: 100%;
    left: 10px;
    color: faa31a !important;
    width: 40px;
}
.top-swiper .swiper-button-next::after {
    content: "";
    z-index: 1000;
    position: absolute;
    height: 100%;
    color: orange !important;
    width: 40px;
}

/**---------------------------- Responsive part -------------------------**/
@media (max-width: 1600px) {
    .carousel-up {
        top:80px !important; 
     }
}
@media (max-width: 1400px) {
    .carousel-up {
        top:80px !important; 
     }
     .slick-slide .small-imgs{
        height: 350px !important;
        width: 220px !important;
    }
}
@media (max-width: 1200px) {
    .slick-slide .small-imgs{
        height: 300px !important;
        width: 190px !important;
    }
    .bg-carousel-height .slick-list {
        padding:15px 0px !important;
    }
    .carousel-up {
       top:70px !important; 
    }
}

@media (max-width: 1024px) {      
    .text-mid-center{
        text-align: center !important;
    }
    .bg-carousel-height .slick-list {
        padding:5px 20px !important;
    }
    .slick-slider {
        margin: 0 0px !important;
    }
    .slick-slide {
        padding: 10px;
        text-align: center;
    }
    .slick-slide .small-imgs {
        height: 100px !important;
        width: 120px !important;
    }
    .carousel-up {
        position: unset !important;
        margin-top: 20px;
    }
    .slick-prev:before,
    .slick-next:before {
        display: none !important;
    }
    .bg-carousel-height .slick-slider {
        margin: 0 15px !important;
    }
    .slick-next{
        right: 0px !important;
    }
    .bg-carousel-height .slick-dots{
        position:relative;
        top:20px;
    }
}

@media (max-width: 991px){
    .sm-hide{
        display: none !important;
    }
    .sm-show{
        display: block !important;
    }
}
