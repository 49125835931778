.channel-bg img{
    height: 180px !important;
    width: 100% !important;
    object-fit: contain !important;
    border-radius: 15px;
}
.flip1 > .front, .flip1 > .back {
    max-height: auto;
    border-radius: 15px;
}
.channels .slick-dots{
    display: none !important;
}