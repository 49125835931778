.plan .modal-content{
    background-color: transparent !important;
    border: none !important;
}
.plan .modal-dialog{
    width: 100% !important;
    --bs-modal-width: 100% !important;
}
.plan-list li{
    line-height: 28px;
    font-size: 18px;
    font-weight: 300;
}
.icons .ico{
    font-size: 22px;
    margin-top: 0px;
}
.plan-padding{
    padding: 30px 25px 10px 25px !important;
}

/**---------------------------- Responsive part -------------------------**/
@media (max-width:786px){
    .plan-padding {
        padding: 20px 10px 20px 10px !important;
    }
    .plan-list li {
        line-height: 24px;
        font-size: 16px;
        text-align: left !important;
    }
    .icons .ico{
        font-size: 16px;
    }
}