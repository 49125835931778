/* body, html{
  background: linear-gradient(to right, #212121, #89601e, #822719) !important;
  height: 100%;
} */
a{
  cursor: pointer;
}
.App-link {
  color: #61dafb;
}
.modal-footer button{
  border: none !important;
}
.form-control:focus{
  background-color: transparent !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
