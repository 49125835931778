.footer-down-images a img{
    width: 130px;
    object-fit: contain;
}

/**---------------------------- Responsive part -------------------------**/
@media (max-width: 1400px) and (min-width: 1200px) {
    .footer-down-images a img{
        width: 100px;
    }
}
@media (max-width: 1200px) and (min-width: 991px) {
    .footer-down-images a img{
        width: 100px;
    }
}
@media (max-width:830px){
    .footer-bg{
        padding-bottom: 40px;
    }
}