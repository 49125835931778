.heading-color {
    color: red !important;
    z-index: 1000 !important;
    text-align: center;
}
.position-relative {
    position: relative !important;
}
.bg-image {
    position: relative;
    height: auto !important;
    width: 100%;
}
.one{
        display: flex;
        position: relative;
}
.two{
    position: relative;
    top: 0;
    left: 0;
}
.two1{
    top: 0px;
    left: 0px;
    -webkit-box-flex: 1;
    flex-grow: 1;
    display:flex;
    overflow: visible;
    position: relative;
    height: calc(44.68vw);
    max-height: calc(100vh - 210px) !important;
}
.three{  
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
}
video {
    top: 0px;
    left: 0px;
    -webkit-box-flex: 1;
    flex-grow: 1;
    width: 100%;
    height: calc(48.68vw);  
    overflow: visible;
    position: relative;
    max-height: calc(100vh - 50px) !important;
    --video-width: 1920;
    object-fit: cover;
}
.bg-image::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: -1;
    height: 99%;
    background: linear-gradient(29.63deg,rgb(0 0 0 / 50%) 11.82%,rgb(249 249 249 / 19%) 173.33% );
    transition: all 0.3s ease;
}
.hero-content {
    position: absolute !important;
    padding: 180px 0px 180px 0px;
    top: 50px;
}
.left-content {
    width: 30%;
}
.left-content h1 {
    font-size: 50px;
    line-height: 60px;
    font-weight: 700;
    color: #fff;
}
.left-content p {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    color: #fff;
}
.extra-small-content {
    display: flex;
}
.fa-icon {
    margin: 1px 1px !important;
}
.features {
    display: flex;
}
.bottom-icons {
    font-size: 20px !important;
    margin: 0px 8px 0px 0px !important;
}
.swiper-relative {
    position: relative;
}
.swiper {
    z-index: 2 !important;
}
#yt-embed {
    cursor: not-allowed;
}
.video-control {
    position: absolute;
    left: 20em;
    bottom: 1em;
    min-width: 7.5em;
    padding: 0.5em 1em;
    text-align: left;
    border-radius: 9in;
    background: #fffb;
}
.swiper-button-next::after,
.swiper-button-prev::after {
    content: "";
}
.play-btn{
    border-radius: 50%;
    padding: 9px 17px 14px 17px;
    border: none !important ;
    background-color: transparent;
    color: var(--primary-color) ;
    font-size: 60px;
    /* margin-top: 200px; */
}

/**---------------------------- Responsive part -------------------------**/
@media (max-width: 1400px) {
.hero-content {
    padding: 150px 0px 150px 0px;
    top: 30px;
}
}
@media (max-width: 1280px) {    
    video {
        top: 0px;
        left: 0px;
        -webkit-box-flex: 1;
        flex-grow: 1;
        width: 100%;
        height: calc(55.68vw);  
        overflow: visible;
        position: relative;
        max-height: calc(100vh - 30px) !important;
        --video-width: 1920;
        object-fit: cover;
    }
    .hero-content {
        padding: 130px 0px 80px 0px;
        top: 30px;
    }
}

@media (max-width: 880px) {
    video {
        top: 0px;
        left: 0px;
        -webkit-box-flex: 1;
        flex-grow: 1;
        width: 100%;
        height: calc(60.68vw);  
        overflow: visible;
        position: relative;
        max-height: calc(100vh - 100px) !important;
        --video-width: 1920;
        object-fit: cover;
    }
    .hero-content {
        padding: 80px 0px 80px 0px;
        top: 30px;
    }
}

@media (max-width: 767px) {
.play-btn  {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -260%);
    margin-top: 0px !important;
    padding: 5px 10px 9px 9px;
    font-size: 40px;
    height: 40px;
    width: 40px;
}
.hero-slick {
    margin-top: 70px !important;
}
.bg-image::after {
    display: none !important;
}
.hero-slick .slick-dots {
    bottom: 0px !important;
    margin-bottom: 20px;
}
}
