  img, .overlay {
    transition: 0.3s all;
    border-radius: 3px;
  }
  .bg {
    float: left;
    max-width: 250px;
    max-height: 165px;
    position: relative;
  }
  .episode-tabs{
    position: relative !important;
    overflow: hidden !important;
    display: flex !important;
    align-items: center !important;
    width: 100% !important;
    border-bottom: 1px solid #e5e5e5 !important;
  }
  .bg img{
    content: '';
    position: relative;
    height: 100%;
    width: 100%;
    opacity: 0.7;
    border-radius: 15px;
    background: linear-gradient( 52.63deg, rgb(0, 0, 0) 57.82%, rgb(0, 0, 0) 130.33% ) !important;
  }
  .bg img {
    width: 250px;
    height: 150px;
    margin-bottom: -4px;
    border-radius: 15px;
  }
  .bg .overlay .episode-text {
    position: absolute;
    bottom: 0;
    left: 0;
}
.episode-tabs .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #faa31a ;
    background-color: transparent !important;
    border-color: transparent !important;
    border: none !important;
}
.episode-tabs button:hover {
    background: transparent;
    color: #faa31a;
    border: none !important;
}
.episode-tabs .nav-link{
    color: #fff !important;
    font-size: 20px;
    font-weight: 500;
}
.episode-tabs .nav-tabs .nav-link {
    margin-bottom: none !important;
    border: none !important;
    border-top-left-radius:unset !important;
    border-top-right-radius: unset !important;
}
.episode-tabs .nav-link:focus-visible {
    outline: none !important;
    box-shadow: none !important;
}
.nav-tabs {
    --bs-nav-tabs-border-width: unset !important;
    --bs-nav-tabs-border-color:unset !important;
    --bs-nav-tabs-border-radius: unset !important;
    --bs-nav-tabs-link-hover-border-color: unset !important;
    --bs-nav-tabs-link-active-color: unset !important;
    --bs-nav-tabs-link-active-bg:unset !important;
    --bs-nav-tabs-link-active-border-color: unset !important;
    border-bottom: unset !important;
}

/**---------------------------- Responsive part -------------------------**/
  @media screen and (max-width: 1400px) {
    .bg img {
        width: 200px;
        height: 150px;
      }
    }
  @media screen and (max-width: 1200px) {
    .bg {
      max-width: 100%;
    }
    .bg img {
        width: 160px;
        height: 150px;
      }
  }
  @media screen and (max-width: 991px) {
    .bg img {
        width: 100%;
        height: 150px;
        border-radius: 15px;
      }
  }
  @media screen and (max-width: 767px) {
    .bg img {
        width: 100%;
        height: 170px;
        border-radius: 15px;
      }
  }
  @media screen and (max-width: 500px) {
    .bg img {
        width: 100%;
        height: 100px;
        border-radius: 15px;
      }
  }
  