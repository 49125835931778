.sign-bg {
    background-color: transparent !important;
    border-radius: 10px;
    display: flex;
    justify-content: center;
}
.sign-form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
}
.modal {
    background-color: transparent !important;
    --bs-modal-bg: transparent !important;
}
.modal-content {
    border-radius: 10px !important;
}
.form-control {
    border: none;
}
.modal-backdrop {
    --bs-backdrop-zindex: 1050;
    --bs-backdrop-bg: #181717fa !important;
    --bs-backdrop-opacity: 0.7 !important;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--bs-backdrop-zindex);
    width: 100vw;
    height: 100vh;
    background-color: var(--bs-backdrop-bg);
}
#msform {
    background-color: rgb(0 0 0 / 63%);
    border-radius: 10px;
}
.mobile-logo1 {
    display: flex;
    justify-content: center;
    align-items: center;
}
.sign-modal .modal-dialog {
    transform: none;
    max-width: 550px;
}
.social-images {
    width: 100%;
    max-width: 230px;
    margin: 0px 5px;
    height: 55px;
    padding: 9px 15px;
    border-radius: 6px;
    border: solid 1px #2d2d2d;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.modal-header {
    border-bottom: none !important;
    display: grid !important;
    justify-content: center !important;
}
.sign-in-btn Button {
    padding: 15px 15px;
    width: 100%;
}
.sign-in-btn {
    padding: 30px 50px 30px 50px;
}
.modal-footer {
    border-top: none !important;
}
.mini-heading p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 300 !important;
}
.social-images a img {
    border: none;
    height: 30px;
    padding: 6px;
}
.social-login {
    display: flex;
}
.modal {
    padding-left: 0px !important;
}
.input-bg {
    background-color: rgb(25 24 24 / 80%) !important;
    padding: 8px 20px;
    width: 100%;
}
.form-control:focus {
    color: #fff !important;
    background-color: none !important;
    border-color: #faa31a;
    outline: none !important;
    box-shadow: none !important;
}
#msform fieldset:not(:first-of-type) {
    display: none;
}
#msform input,
#msform textarea {
    padding: 15px 15px 15px 15px;
    width: 100%;
}

#msform .action-button {
    width: 100%;
    background: radial-gradient(
        circle,
        rgba(250, 163, 26, 0.989233193277311) 0%,
        rgba(125, 89, 31, 0.8323704481792717) 100%
    );
    border: 0 none;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
}
#msform .action-button-previous {
    width: 100px;
    color: white;
    border: 0 none;
    cursor: pointer;
    padding: 9px 5px;
    background-color: transparent !important;
    margin: 1px 5px 10px 0px;
    float: right;
}
.input-bg::-webkit-input-placeholder {
    color: #fff !important;
    font-weight: 300 !important;
}
.otp-number p {
    box-shadow: inset 0 0 3px 0 rgba(255, 255, 255, 0.33);
    border-radius: 8px;
    width: 40px;
    padding: 8px 10px;
}
.modal-lg,
.modal-xl {
    --bs-modal-width: 600px !important;
}
.bg-signimages::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0 !important;
    background: url(../../images/bg-register.png);
    --video-width: 1920;
    --video-height: 1080;
    aspect-ratio: var(--video-width) / var(--video-height);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.bg-signimages::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1 !important;
    background-color: #858584b3;
    opacity: 0.6;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
/**---------------------------- Responsive part -------------------------**/
@media (max-width: 1300px) and (min-width: 1270px) {
    #msform input,
    #msform textarea {
        padding: 8px 15px 8px 15px;
        width: 100%;
    }
    .sign-in-btn {
        padding: 10px 50px 10px 50px !important;
    }
    .sign-in-btn Button {
        padding: 8px 15px;
    }
    .social-images {
        height: 35px;
        padding: 0px 15px;
    }
}
@media (max-width: 1200px) {
    .sign-form {
        width: 100%;
    }
}

@media (max-width: 1100px) {
    .social-images {
        max-width: 100%;
    }
    .sign-in-btn {
        padding: 30px 100px 30px 100px !important;
    }
}

@media (max-width: 991px) {
    .sign-in-btn {
        padding: 30px 50px 30px 50px !important;
    }
}

@media (max-width: 600px) {
    .social-login {
        display: block !important;
    }
    .social-login span {
        margin-bottom: 10px;
    }
    .social-images {
        max-width: 100%;
    }
}

@media (max-width: 480px) {
    .sign-in-btn {
        padding: 10px 20px 10px 20px !important;
    }
    .otp-number p {
        width: 36px;
        padding: 6px 6px;
    }
}
