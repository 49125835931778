.edit-modal .modal-dialog {
    background-color: #0000008f  !important;
    --bs-modal-bg: #0000008f  !important;
    border-radius: 10px;
}
.modal-header .btn-close {
    margin: calc(-3.5 * var(--bs-modal-header-padding-y)) calc(-9.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
    background: #fff !important;
    opacity:unset !important;
}
.form-control:focus{
    border-color:unset !important;
}
.modal-header .btn-close:hover{
    color: orange !important;
    background: #fff !important;
    opacity:unset !important;
    box-shadow: none !important;
}
.bg-transparent{
    background: transparent !important;
}
.edit-modal{
    margin: 10% 0%;
}

/**---------------------------- Responsive part -------------------------**/
 @media (max-width:991px){
    .edit-modal{
        margin: 20% 0%;
    }
 }
 @media (max-width:500px){
    .edit-modal{
        margin: 50% 0%;
    }
 }
