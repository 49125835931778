    .side-list {
        float: left;
        position: fixed; 
        top:50%;
        transform: translate(-50%, -50%);
        z-index: 3;
        left: 20px;
        background: transparent;
    }
    .side-list ul {
        text-align: center;
        list-style: none;
        padding-left: 0px !important;
    }
    .pipe{
        display: none;
    }
    .side-list ul li a {
        position: relative;
        color: #fff ;
        width: 70px;
        height: 70px;
        left: 20px;
        line-height: 60px;
        cursor: pointer;
        transition: all 0.4s ease-out;
    }
    .side-list ul li a .side-icons {
        transition: all 0.2s ease-out;
        width: 40px;
        font-size: 28px;
        margin-right: 6px;
        margin-bottom: 6px;
        border-right:none !important;
    }
    .side-list ul li a .side-icons:hover {
        color: #faa31a;
        transform: scale(1.2);                
        border-radius: 4px;
        width: 60px;
        font-size: 30px;
        margin-right: 3px;
        margin-bottom: 6px;
    }
    .side-list ul li a:after {
        position: absolute;
        color: #fff;
        top: 0;
        font-size: 16px;
        font-weight: 500;
        left: 70px;
        width: 100px;
        opacity: 0.5;
        transform-origin: 0 100%;
        transition: all 0.4s ease-out;
    }
    .side-list ul li a:hover {
        transform: translateX(-70px);
    }
    .side-list ul li a:hover:after {
        opacity: 1;
        transform: perspective(400px) rotateY(0deg) scale(1);
    }
    .position-relative {
        position: relative !important;
    }
    .side-list ul li {
    transition: 2s ease-in-out;
    }
     .side-list ul li .active{
        color: #faa31a ;
        transform: none !important;                
        border-right:3px solid #faa31a !important;
        border-radius: 4px;
        font-size: 40px !important;
      }

/**---------------------------- Responsive part -------------------------**/
@media (max-width: 1300px) {
    .side-list ul li a .side-icons {
        width: 35px !important;
        font-size: 28px !important;
        margin-right: 6px !important; 
    }
}

@media (max-width: 991px) {
    .side-icons {
        font-size: 25px;
    }
    .side-list ul li a {
        left: 6px;
        line-height: 50px;
    }    
    .side-list ul li a .side-icons {
        width: 30px !important;
        font-size: 25px !important;
        margin-right: 10px !important; 
    }
}

@media (max-width: 830px) {    
.pipe{
    display: block;
}
.side-list ul li .active{
    color: #faa31a ;        
    border-right:none !important;
    font-size: 30px !important;
}
.side-list ul li a {
    display: flex;
    justify-content: center !important;
    line-height: 30px;
}
.icon-hide{
    display: none !important;
}
.side-list ul {
    display: flex;
    justify-content: space-between !important;
    padding: 10px 20px 0px 20px !important;
}
.side-list ul li a .icon-hide{
    display: none;
}
.side-list {
    bottom: 0; 
    top:auto;
    left: 0;
    transform: none !important;
    right: 0;
    z-index: 1000;
    margin: 0px 0px 0px 0px;
    background: linear-gradient(to bottom, #faa31a, #822719);
}
.side-list ul li a {
    height: auto;
    width: 100% !important;
}
.side-list ul li a:hover:after {
    opacity: 1;
    transform: none;
}
.side-list ul li a:hover {
    transform: none;
}
}
