.user-header-img img{
    border-radius: 50%;
    height: 40px;
    width: 40px;
    object-fit: cover;
}
.user-header-img button{
    border:none !important;
}
.user-header-img .dropdown, .user-header-img .dropdown button{
    border:none !important;
}
.user-header-sm{
    display: none !important;
}

/**---------------------------- Responsive part -------------------------**/
@media (max-width:830px){
    .user-header-sm{
        display: block !important;
    }
}