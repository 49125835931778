.top-10 a img {
    height: auto;
    width: 100%;
    object-fit: contain;
    border-radius: 15px;
}
#top-show {
  position: relative;
  max-width: 285px;
  height: auto;
  border-radius: 15px;
  background-color: hsla(39, 100%, 2%, 0.23);
  border: 1px solid hsla(39, 100%, 2%, 0.36);
  overflow: hidden;
}
.top-10 h1{
    font-size: 120px !important;
    font-weight: bold !important;
    line-height: 130px !important;
    bottom: -40px;
    position: absolute;
    text-shadow: 4px 4px 1px rgb(240 143 5);
    left: -5px;
    color: #5c5b59;
}
.swiper{
  overflow-y: visible !important;
}
.top-number{
  animation-name: tilt-n-move-shaking;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes tilt-n-move-shaking {
    0% { transform: translate(0, 0) rotate(0deg); }
    25% { transform: translate(5px, 5px) rotate(5deg); }
    50% { transform: translate(0, 0) rotate(0eg); }
    75% { transform: translate(-5px, 5px) rotate(-5deg); }
    100% { transform: translate(0, 0) rotate(0deg); }
  }

/**---------------------------- Responsive part -------------------------**/
@media (max-width: 768px) {
  .top-10{
    margin-bottom: 10px;
  }
  .top-10 h1 {
    font-size: 80px !important;
    line-height: 90px !important;;
  }
  .top-10 a img {
  border-radius: 0px;
  }
  #top-show{
  max-width: 100%;
}
}