.quick-support img{
    border: 1px solid #faa31a;
    border-radius: 50%;
    padding: 20px;
    }
    .bg-intro{
        background: linear-gradient(to right, #212121, #89601E, #822719 ) !important;
    }
    .core-features{
        border-radius: 12px;
        width: 100%;
        max-width: 250px;
    }
    
    .screen-imgs{
        max-width: 100%;
        width: 100%;
        min-height: auto;
    }
    .screen-imgs img{
        width: 100%;
        max-width: 100%;
        height: auto;
        object-fit: contain;
        border-radius: 10px;
    }
    .main-screen-img{
        max-width: 700px;
        width: 100%;
    }
    .main-screen-img img{ 
        max-width: 100%;
        width: 100%;
        object-fit: cover;
    }
    .icon-image img{
        height: 150px;
        width: 100px;
        object-fit: contain;
    }
    .intro-setup-image{
        background-image: url(../../images/bg-register.png);
        background-repeat: no-repeat;
        background-position: top;
        position: relative;
        z-index: 1;
    }
    .purchase-button:hover{
        background:linear-gradient(to right, #212121, #89601e) !important;
    }
    .purchase-button{
        background: #faa31a !important;
        padding: 10px 20px !important;
    }
    .intro-text, .active,  .demo .dropdown-toggle:hover, .button-hover .red:hover,
    .button-hover .blue:hover, .button-hover .light:hover{
        color:#faa31a !important;
    }
    .button-hover:hover{
       color:#faa31a !important; 
    }
    .intro-setup-image::before {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #1b1919;
        overflow: hidden;
        z-index: -1;
        opacity: 0.8;
    }
    .main-intro-bg{
        background-image: url(../../images/afterbg.png);
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        z-index: 1;
    }
    .demo .dropdown-toggle{
        font-size: 16px;
        padding-left: 0px !important;
    }
    .demo button{
        line-height: 20px !important;
    }
   .button-hover .red, .button-hover .blue, .button-hover .light{
        font-size: 15px;
        line-height: 10px !important;
    }
    .main-intro-bg::before {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #1b1919;
        overflow: hidden;
        z-index: -1;
        opacity: 0.8;
    }
    .dropdown-toggle::after {
        margin-top: 10px !important;
    }
    .all-images {
        height: 430px;
        max-width: 100%;
    }
    .all-images img {
        height: auto;
        max-width: 100%;
        object-fit: contain;
    }
    .all-pages{
        max-width: 100%;
        width: 100%;
    }
    .all-pages img{
        max-width: 100%;
        width: 100%;
        object-fit: contain;
        border-radius: 20px;
    }
    .intro-tabs a.tab {
        position: relative;
        display: table-cell;
        transition: all ease .3s;
        transform: translate3d(0, 0, 0);
        color: #636d84;
        white-space: nowrap;
        cursor: pointer;
    }
    .intro-tabs a.tab li{
        font-size: 16px;
        font-weight: normal !important;
    }
    .intro-tabs {
        display: table;
        border-collapse: separate;
        table-layout: auto;
    }
    .purchase-sm{
        display: none !important; 
    } 
    .intro-loop a img {
        height: 40px !important;
        object-fit: contain;
    }
    .intro-loop {
        line-height: 40px;
        margin-top: 16px;
        text-transform: uppercase;
        font-size: 1.4em;
    }
    /**---------------------------- Responsive part -------------------------**/
    @media (max-width:1200px){    
        .all-images {
            height: auto;
            max-width: 100%;
        }
    }
    @media(max-width:830px){
        .demo .dropdown-toggle, .button-hover .red, .button-hover .blue, .button-hover .light{
            line-height:unset !important;
        }    
        .purchase-view{
            display: none;
        }
        .purchase-sm{
            display: block !important;
        }
        .button-hover button {
            margin-top: 0px;
            padding-left: 4px;
        }
        .intro-tabs a.tab {
            display: grid;
        }
        .intro-tabs {
            display: grid;
            border-collapse:unset;
        }
    }
    @media (max-width:768px){
        .intro-loop a{
            display: flex;
            justify-content: center;
        }
        .screen-imgs{
            width: 100%;
        }
        .core-features{
            max-width: 100%;
        }
        .intro-footer{
            display: grid !important;
            justify-content: center !important;
        }
    }