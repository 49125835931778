.blogs-images{
    overflow: hidden;
    height: auto;
    border-radius: 10px;
}
.blogs-images img{
    height: auto;
    max-height: 550px;
    width: 100%;
    object-fit:fill ;
}
.text-style .movie{
    background: linear-gradient(to right,hsl(39deg 100% 48.25% / 43%),hsl(222deg 100% 58% / 0));
    border-left: 3px solid #faa31a;
    display: inline-block;
}
.recent-imgs{
    overflow: hidden;
    max-width: 100%;
    height: auto;
}
.recent-imgs img{
    height: 80px;
    width: 100px;
    border-radius: 10px;
    object-fit: fill;
}

/**---------------------------- Responsive part -------------------------**/
@media (max-width:768px){
    .recent-imgs img{
        height: 80px;
        width: 80px;
        max-width: 100%;
    }
}