.acc-header{
    background:#faa31a !important;
}
.accordion-button:not(.collapsed){
    background: linear-gradient( to left, rgb(240 237 237 / 27%) -30%, rgb(17 14 7) 117% ) !important;
    color: #fff;
}
.accordion-button:focus {
    z-index: 3;
    border-color:none !important;
    outline: none !important;
    box-shadow:none !important;
}
.accordion-button{
    background: linear-gradient( to left, rgb(240 237 237 / 27%) -30%, rgb(17 14 7) 117% ) !important;
    border: none !important;
    color: #fff;
    border-radius: 0px !important;
    padding: 20px;
}
.accordion-button:hover{
    transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1) !important;
    background-color: rgb(65,65,65);
}
.accordion-item {
    border-radius: 0px !important;
    border:none !important;
}

/**---------------------------- Responsive part -------------------------**/
@media (max-width:991px){
    .accordion-button{
        padding: 15px;
    }
}