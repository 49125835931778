.registration-form{
    position: relative;
    /* top: 10%; */
    left: 0%;
    transform: translate(0%, 10%);
    z-index: 3;
}
.register-btn {
    padding: 30px 50px 30px 50px !important;
}
.register-btn button{
   padding:  15px 15px;
}
.bg-images::after{
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0 !important;
    background: url(../../images/bg-register.png);
    --video-width: 1920;
    --video-height: 1080;
    aspect-ratio: var(--video-width) / var(--video-height);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.bg-images::before{
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1 !important;
    background-color: #858584b3;;
    opacity: 0.6;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.register-form-content{
    z-index: 100;
}

/**---------------------------- Responsive part -------------------------**/

@media (max-width:1300px){    
    .register-btn {
        padding: 15px 50px 15px 50px !important;
    }
    .register-btn button{
        padding:  8px 15px;
     }
}
@media (max-width:767px){    
    .registration-form{
        top: 2%;
        transform: translate(0%, 2%);
    }
    .registration-form{
        margin: 0px 15px !important;
    }
    .register-btn {
        padding: 30px 30px 30px 30px !important;
    }
    .bg-images::after, .bg-images::before{
        display: none;
    }
}