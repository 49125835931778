.bg-search{
    background-color: rgb(82 44 23 / 0%);
    border: 1px solid grey;
    padding: 4px 20px !important;
}
.bg-only{
    display: none;
}
.search-icon{
    font-size: 25px;
    font-weight: 200 !important;
}
.header-two{
    display: none;
}
.pt-search{
    display: none;
}

/**---------------------------- Responsive part -------------------------**/
    @media (max-width:815px){
    .bg-search{
        display: none !important;
    }
    .bg-only{
        display: block;
        background-color: rgb(82 44 23 / 48%);
        border: 1px solid grey;
        padding: 4px 20px !important;
    }
    .pt-search{
        padding-top: 50px;
        display: block !important;
    }
    }