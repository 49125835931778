.video-react .video-react-big-play-button {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    z-index: 3 !important;
    border-radius: 50% !important;
    width: 30px !important;
    height: 30px;
}
.video-react-controls-enabled {
    padding-top: 52.7% !important;
}
@media (max-width: 1600px) and (min-width: 1300px) {
    .video-react-controls-enabled {
        padding-top: 56.5% !important;
    }
}
@media (max-width: 1300px) and (min-width: 1260px) {
    .video-react-controls-enabled {
        padding-top: 53.7% !important;
    }
}
@media (max-width: 1260px) {
    .video-react-controls-enabled {
        padding-top: 56.5% !important;
    }
}
