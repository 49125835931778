.container .text{
    text-align: center;
    -webkit-text-fill-color: transparent;
  }
  .container form{
    padding: 30px 0 0 0;
  }
  .container form .form-row{
    display: flex;
    margin: 32px 0;
  }
  form .form-row .input-data{
    width: 100%;
    height: 40px;
    margin: 0 20px;
    position: relative;
  }
  form .form-row .textarea{
    height: 70px;
  }
  .input-data input,
  .textarea textarea{
    display: block;
    width: 100%;
    height: 100%;
    border: none;
    font-size: 17px;
    border-bottom: 2px solid  rgb(132 99 47 / 55%)
  }
  .input-data input:focus ~ label, .textarea textarea:focus ~ label,
  .input-data input:valid ~ label, .textarea textarea:valid ~ label{
    transform: translateY(-20px);
    font-size: 14px;
    color: #faa31a;
  }
  .textarea textarea{
    resize: none;
    padding-top: 10px;
  }
  :focus-visible {
    outline: none !important;
}
  .input-data label{
    position: absolute;
    pointer-events: none;
    bottom: 10px;
    font-size: 14px;
    transition: all 0.3s ease;
  }
  .textarea label{
    width: 100%;
    bottom: 40px;
    background: transparent !important;
  }
  .input-data .underline{
    position: absolute;
    bottom: 0;
    height: 2px;
    width: 100%;
  }
  .input-data .underline:before{
    position: absolute;
    content: "";
    height: 2px;
    width: 100%;
    background: #faa31a;
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 0.3s ease;
  }
  .input-data input:focus ~ .underline:before,
  .input-data input:valid ~ .underline:before,
  .textarea textarea:focus ~ .underline:before,
  .textarea textarea:valid ~ .underline:before{
    transform: scale(1);
  }
  .submit-btn .input-data{
    overflow: hidden;
    height: 45px!important;
    width: 25%!important;
  }
  .submit-btn .input-data .inner{
    height: 100%;
    width: 300%;
    position: absolute;
    left: -100%;
    background: -webkit-linear-gradient(right, #faa31a, #6b470c, #967d54, #614520);
    transition: all 0.4s;
  }
  #contact{
    width: 600px;
    height: 500px;
  }
  .form-outline{
    border: 2px solid #ffa31a;
    border-radius: 10px;
  }
  #mapid { height: 1000px; }
  .submit-btn .input-data:hover .inner{
    left: 0;
  }
  .submit-btn .input-data input{
    background: none;
    border: none;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    position: relative;
    z-index: 2;
  }
  .input-data input, textarea{
    background-color: transparent !important;
    color: #fff;
  }
  .address-border{
    border:1px solid #faa31a;
    border-radius: 10px;
    padding: 30px 10px;
    transition: transform .5s;
    overflow: hidden;
  }
  .address-border:hover{
    background: radial-gradient(circle, rgb(132 99 47 / 55%) 0%, rgb(31 24 11) 100%) !important;
    border: none !important;
    transform: scale(1.1);
  }

  /**---------------------------- Responsive part -------------------------**/
  @media (max-width:1400px){
    #contact {
      width: 100%;
      height: 500px;
    }
  }
  @media (max-width: 700px) {
    .submit-btn .input-data {
      width: 100% !important;
  }
    .container form .form-row{
        display: flex;
        margin: 32px 8px;
      }
    #contact {
        width: 100%;
        height: auto;
    }
    .container .text{
      font-size: 30px;
    }
    .container form{
      padding: 10px 0 0 0;
    }
    .container form .form-row{
      display: block;
    }
    form .form-row .input-data{
      margin: 35px 0!important;
    }
  }