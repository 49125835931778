.about-bg-image{
    background-image: url(../../images/bg-register.png);
    background-repeat: no-repeat;
    background-position: top;
    position: relative;
    z-index: 1;
}
.about-bg-image::before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #1b1919b0;
    overflow: hidden;
    z-index: -1;
    opacity: 0.6;
}
.team-images{
    position: relative;
    height: 300px;
    border-radius: 15px;
    background-color: hsla(39, 100%, 2%, 0.23);
    border: 1px solid hsla(39, 100%, 2%, 0.36);
}
.team-images img{
    height: auto;
    width: 100%;
    object-fit: contain;
    border-radius: 15px;
    border: 1px solid grey;
    transition: transform 0.5s ease-in-out;
    transform-origin: center;
}
.team-images img:hover{
    transform: scale(1.1) translateY(0%) translateX(0%);
}
.bg-transparent-shadow::after{
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
   background: url(../../images/afterbg.png);
    overflow: hidden;
    z-index: -1;
    opacity: 0.6;
}
.bg-text{
    background: linear-gradient(to right,hsl(39deg 100% 48.25% / 43%),hsl(222deg 100% 58% / 0));
    border-left: 4px solid var(--primary-color) !important;
    display: inline-block;
}
.services-images{
    height: 400px;
   max-width: 100%;
}
.services-images img{
    height: auto;
    max-width: 100%;
    object-fit: contain;
}

/**---------------------------- Responsive part -------------------------**/
@media (max-width:1500px){    
    .team-images{
        height: auto;
    }
}
@media (max-width:1024px){
    .services-images {
        height: auto;
    }
}
@media (max-width:767px){
    .bg-text {
        display: block;
    }
}