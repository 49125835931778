.bg-carousel-img::after{
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: url(../../images/logo2.jpg);
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
    z-index:-1;
}
.bg-carousel-img{
    position: relative;
}
.bg-carousel-img::before {
    content: "<FaChevronLeft />";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 0;
    height: 100%;
    background: linear-gradient( 90.63deg, rgb(15 2 2) 4.82%, rgb(16 7 9 / 50%) 16.33% );
    transition: all 0.3s ease;
}
.trending-swiper .swiper-button-prev::after {
    content: "";
    z-index: 1000;
    font-size: 40px;
    position: absolute;
    height: 100%;
    color: orange !important;
    width: 40px;
}
.trending-swiper .swiper-button-next::after{
    content: ">";
    z-index: 1000;
    font-size: 40px;    
    position: absolute;
    height: 100%;
    color: orange !important;
    width: 40px;
}

