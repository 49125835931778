#main-continue {
    position: relative;
    max-width: 300px;
    width: 100%;
    height: auto;
    border-radius: 15px;
    background-color: hsla(39, 100%, 2%, 0.23);
    border: 1px solid hsla(39, 100%, 2%, 0.36);
    overflow: hidden;
}
#main-continue a img{
    height: 300px;
    max-height: 300px;
    width: 100%;
}
.continue-content {
    position: relative;
    width: 100%;
    max-width: 300px;
    margin: auto;
    overflow: hidden;
  }  
  .continue-content .content-overlay {
    background: rgb(103 125 218 / 77%);
    position: absolute;
    height: 100%;
    width: 150px;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
  }  
  .continue-content .content-overlay{
    opacity: 1;
  }  
  .content-image{
    width: 100%;
  }  
  .content-details {
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }  
  .continue-content .content-details{
    top: 50%;
    left: 50%;
    opacity: 1;
  }  
  .content-details h3{
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.15em;
    margin-bottom: 0.5em;
    text-transform: uppercase;
  }  
  .content-details p{
    color: #fff;
    font-size: 0.8em;
  }
  .fadeIn-left{
    left: 20%;
  }
  
  /**---------------------------- Responsive part -------------------------**/
  @media (max-width:1200px){
    #main-continue a img {
      height: auto;
    }
  }
  @media (max-width:768px){    
    .continue-content .content-overlay {
        height: 100%;
        width: 100px;
      }
  }