.flip {
    position: relative;
    max-height: 250px !important;
    max-width: 300px !important;
}
.flip > .front, .flip > .back {
    display: block;
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition-duration: 0.5s;
    transition-property: transform, opacity;
}
.front-bg a img{
    height: 250px !important;
    width: 100% !important;
    object-fit: fill !important;
    opacity: 0.2;
    border-radius: 15px;
}
.front-bg::before{
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient( 52.63deg, rgb(0, 0, 0) 57.82%, rgb(0, 0, 0) 130.33% ) !important;
    border-radius: 15px;
    opacity: 0.7;
    z-index: -1;
}
.front h4{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.flip > .front {
    transform: rotateY(0deg);
}
.flip > .back {
    position: absolute;
    opacity: 0;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    transform: rotateY(-180deg);
}
.flip > .front, .flip > .back {
    display: block;
    color: white;
    background-size: cover !important;
    background-position: center !important;
    max-height: 250px;
    background: #313131;
    border-radius: 15px;
}
/**---------------------------- Responsive part -------------------------**/
    @media (max-width:1400px) {
        .flip {
            max-width: 230px !important;
        }
    }
    @media (max-width:768px) {
        .flip {
            max-height: 150px !important;
            max-width: 130px !important;
        }
        .flip > .front, .flip > .back {
            max-height: 150px;
        }
        .front-bg a img{
            height: 150px !important;
        }
    }
