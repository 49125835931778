.plan-card {
 background-color: rgb(25 24 24 / 80%) !important;
  color: #fff;
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius:10px;
}
.plan-next-btn button{
  border: none !important;
  background: radial-gradient(circle, rgba(250,163,26,0.989233193277311) 0%, rgba(125,89,31,0.8323704481792717) 100%);
  padding: 15px 100px;
  border-radius: 8px;
}
.plan-main{
  z-index: 1;
}
.plan-card .plans-btn {
  border-radius: 0px 0px 10px 10px;
  padding: 10px 10px;
  background-color: rgb(78 77 77 / 0%) !important;
  border-top: 1px solid var(--primary-color) ;
  color: var(--primary-color)  !important;
  text-decoration: none;
  transition: all .4s ease;
}
.plan-card:hover{
  box-shadow: var(--primary-color)  2px 0 5px;
}
.plans-btn:hover, .plans-btn:focus {
  -webkit-text-fill-color: #fff !important;
  box-shadow: var(--primary-color)  2px 0 5px;
  background: radial-gradient(circle, rgba(250,163,26,0.989233193277311) 0%, rgba(125,89,31,0.8323704481792717) 100%);
  border: 0 none;
}

/**---------------------------- Responsive part -------------------------**/
@media (max-width:768px){
  .plan-next-btn button {
    padding: 10px 70px;
}
}