.user-profile-img img{
    border-radius: 50%;
    height: 120px;
    width: 120px;
    object-fit: cover;
}
.edit-button button{
    border:none !important;
    padding: 5px 20px;
    border-radius: 4px;
    background: radial-gradient(circle, rgba(250,163,26,0.989233193277311) 0%, rgba(125,89,31,0.8323704481792717) 100%);
}

/**---------------------------- Responsive part -------------------------**/
@media (max-width:991px) and (min-width:767px){
    .edit-button{
        margin-top:80px;
    }
}