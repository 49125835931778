
.tabs a {
	text-decoration: none !important;
	font-size: 24px;
}
#tabs {
	z-index: 1000;
}
.tabs {
	font-size: 0.8125rem;
}
.responsive {
	width: 100%;
	overflow-x: auto;
}
.tabs {
	display: table;
	border-collapse: separate;
	table-layout: auto;
}
.tabs.tabs-center {
	margin: auto;
}
.tabs.tabs-justify {
	width: 100%;
}
.tabs a.tab {
	position: relative;
	display: table-cell;
	transition: all ease .3s;
	padding: 10px 80px 10px 0px;
	transform: translate3d(0, 0, 0);
	color: #636d84;
	white-space: nowrap;
	cursor: pointer;
}
.tabs a.tab.active:after {
	opacity: 1;
	transform: scale(1, 1);
}
.tab-content>.active{
	border: none !important;
}
.over{
    overflow-y: auto;
}

/**---------------------------- Responsive part -------------------------**/
@media (max-width:600px){
.tabs a.tab {
	padding: 10px 15px 10px 0px;
}	
.tabs a {
	font-size: 17px;
}
}