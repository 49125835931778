.heading-text {
    display: flex;
    justify-content: space-between;
}
.trending-imgs a img,
.trending-imgs {
    max-width: 290px;
    object-fit: cover;
    border-radius: 15px;
    border: 1px solid grey;
}
.col-zoom:hover {
    -ms-transform: scale(1.1); 
    -webkit-transform: scale(1.1); 
    transform: scale(1.1);
    margin-top: 0px;
}
.col-zoom #main img {
    height: auto;
    width: 100%;
    object-fit: contain;
    border-radius: 15px;
    border: 1px solid grey;
    transition: transform 0.5s ease-in-out;
    transform-origin: center;
}
#main {
    position: relative;
    height: 422px;
    border-radius: 15px;
    background-color: hsla(39, 100%, 2%, 0.23);
    border: 1px solid hsla(39, 100%, 2%, 0.36);
    overflow: hidden;
}
div#main:hover > #sub {
    bottom: 0;
}
div#sub {
    position: absolute;
    bottom: -90px;
    width: 100%;
    height: auto;
    background: linear-gradient(to right, #212121, #89601e);
    -webkit-transition: bottom 0.5s ease;
    -moz-transition: bottom 0.5s ease;
    -o-transition: bottom 0.5s ease;
    -ms-transition: bottom 0.5s ease;
    transition: bottom 0.5s ease;
}
.movies-btn{
    border-radius: 50%;
    padding: 4px 8px 8px 10px;
    border: 1px solid #faa31a;
    background-color: #413b3b;
    color: #faa31a;
    font-size: 18px;
    height: 40px;
    width: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}
.title-top{
    margin-top: 20px;
}

/** ------------The Transformation -------**/
#main:hover img {
    transform: scale(1.25) translateY(0%) translateX(-8%);
}

/**---------------------------- Responsive part -------------------------**/
@media (max-width: 1400px) and (min-width:992px){
    #main {
        height: auto !important;
    }
}

@media (max-width: 1200px) {
#sub{
    padding: 6px 6px !important;
}
#sub div{
    padding: 0px !important;
}
#main {
    height: auto !important;
}
.col-zoom:hover {
    margin-top: 0px;
}
}
@media (max-width: 991px) {
#main,
.col-zoom #main img {
    max-width: 100% !important;
}    
#main{
    height: auto;
}
div#sub{
    bottom: -125px;
}
}

@media (max-width: 768px) {    
.col-zoom #main img{
    border-radius: 0px !important;
}
.col-zoom{
    margin-bottom: 10px;
}
.col-zoom #main img {
    max-width: 100%;
}
.col-zoom:hover { 
    margin-top: 0px;
}
#main{
    border-radius: 2px;
}
.movies-btn  {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    margin-top: 0px !important;
    padding: 4px 9px 9px 10px;
    font-size: 15px;
    height: 35px;
    width: 35px;
}
}

@media (max-width:480px){    
#sub{
    padding: 3px 4px !important;
}    
#sub div{
    padding: 0px !important;
}
#main:hover img {
    transform: none !important;
}
}
