/** -------------------------Primary theme ------------------------**/
:root {
    --primary-color:hwb(37 10% 2%)  !important;
    --blue-color:#0570a8  !important;
    --red-color:#d60606  !important;
    --secondary-color: #536390;
    --font-color: #fff;
}


/** -------------------------- headings ----------------------------- **/
h1 {
    font-size: 60px !important;
    line-height: 70px !important;
    font-weight: 600 !important;
}
h2 {
    font-size: 50px !important;
    line-height: 60px !important;
    font-weight: 600 !important;
}
h3 {
    font-size: 40px !important;
    line-height: 50px !important;
    font-weight: 600 !important;
}
h4 {
    font-size: 30px !important;
    line-height: 40px !important;
    font-weight: 500 !important;
}
h5 {
    font-size: 22px !important;
    line-height: 32px !important;
    font-weight: 500 !important;
}
h6 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
}
p {
    font-size: 18px;
    line-height: 24px;
    color: #fff;
}
.extra-small-content p {
    font-size: 14px;
    margin: 0px 0px !important;
    line-height: 20px;
    font-weight: 500;
}
.font-30 {
    font-size: 30px !important;
    line-height: 34px !important;
}
.font-12 {
    font-size: 12px !important;
    line-height: 18px !important;
}
.font-w-400 {
    font-weight: 400 !important;
}
.font-w-500 {
    font-weight: 500 !important;
}
.font-w-600 {
    font-weight: 600 !important;
}
body {
    font-family: "Inter" !important;
}
/**----------------------------- colors ----------------------------- **/
.color-orange {
    color: var(--primary-color) ;
}
.color-brown {
    color: #89601e;
}
.color-white {
    color: #fff;
}
.color-dark {
    color: #000;
}
.color-grey-hr {
    color: #707070 !important;
}

/** ---------------------- backgrounds ---------------------------- **/
.bg1-gradient {
    background: linear-gradient(to right, #212121, #89601e, #822719) !important;
    background-image: none !important;
}
.bg-white {
    background-color: #fff;
}
.bg-darker {
    background-color: #000 !important;
}
.bg-orange {
    background-color: var(--primary-color)  !important;
}
.bg-gradient-2 {
    background: linear-gradient(to bottom, var(--primary-color), #822719);
}
.bg-grey {
    background-color: #707070 !important; 
}
.bg-grey-light{
    background-color: #5f594a !important;
}
.bg-black-trans{
    background-color: #00000091 !important;
}


/** ------------------------- margins/paddings -------------------------- **/
.pt-40 {
    padding-top: 30px;
}
.pt-80 {
    padding-top: 80px;
}
.mt-150 {
    margin-top: 150px;
}
.mt-100{
    margin-top: 100px;
}
.my-150 {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
}
.my-120{
    padding-top: 200px !important;
    padding-bottom: 120px !important;
}
.my-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
}

/** --------------------------- borders ------------------------------ **/
.border-bottom {
    border-bottom: 2px solid #fff;
}
hr {
    background-color: #dfdfdf !important;
    color: #dfdfdf !important;
    height: 3px;
}
.border-orange {
    border: 3px solid var(--primary-color)  !important;
    border-radius: 15px !important;
}

/** ------------------------------ positions --------------------- **/
.relative {
    position: relative !important;
}
.z-index-1 {
    z-index: 1;
}
.z-index-100 {
    z-index: 100;
}

/** --------------------------- responsive part ------------------------ **/
@media (max-width: 1700px) {
    .pl-300 {
        padding-left: 150px;
    }
}

@media (max-width: 1500px) {
    h5 {
        font-size: 14px !important;
        line-height: 24px !important;
    }
    .extra-small-content p {
        font-size: 11px;
        line-height: 18px;
    }      
    .my-150 {
        padding-top: 100px !important;
        padding-bottom: 100px !important;
    }
    
    .px-100 {
        padding-left: 50px !important;
        padding-right: 50px !important;
    }
}

@media (max-width: 1300px) {    
    h2 {
        font-size: 30px !important;
        line-height: 40px !important;
    }
    .side-list {
        /* margin: 100px 0px 0px 0px; */
    }
    h1 {
        font-size: 40px !important;
        line-height: 50px !important;
    }  
}

@media (max-width: 1024px) {
    .pt-80 {
        padding-top: 40px;
    }
    h4 {
        font-size: 20px !important;
        line-height: 30px !important;
    }
    .font-30 {
        font-size: 20px;
        line-height: 30px;
    }    
    .my-150 {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }
    h2 {
        font-size: 30px !important;
        line-height: 40px !important;
    }
    h3 {
        font-size: 25px !important;
        line-height: 35px !important;
    }
}

@media (max-width: 991px) {
    h5 {
        font-size: 13px !important;
        line-height: 18px !important;
    }
    .px-100 {
        padding-left: 52px !important;
        padding-right: 52px !important;
    }
}

@media (max-width: 830px) {
    .my-120{
        padding-top: 100px !important;
        padding-bottom: 60px !important;
    }
    .mt-100 {
        margin-top: 0px;
    }    
    .px-100 {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}

@media (max-width: 767px) {    

/**----------------------------- Row reverse ------------------------------**/
    .reverse{
        flex-direction: column-reverse;
    }
    .pt-80 {
        padding-top: 20px;
    }
    .mt-100 {
        margin-top: 40px;
    }
    .d-flex-none{
        display: block !important;
    }
    .my-150 {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }
    h1 {
        font-size: 30px !important;
        line-height: 40px !important;
    }
    .pt-40 {
        padding-top: 0px;
    }
    .mt-150 {
        margin-top: 50px;
    }
    .pt-sm-50{
        padding-top: 50px !important;
    }
    .text-align-center {
        text-align: center !important;
    }
}

@media (max-width: 480px) {
    .extra-small-content p {
        font-size: 11px;
        line-height: 18px;
    }
    .px-100 {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}
