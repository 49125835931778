/* * ======================== Sign Button css ====================== */
.position-relative {
    position: relative !important;
}
a .sign {
    padding: 8px 19px !important;
    margin-right: 10px !important;
    background-color: var(--primary-color)  !important;
    border: none !important;
    height: 40px;
    border-radius: 50px !important;
    cursor: pointer !important;
    color: #fff !important;
    transition: all ease-in-out 0s !important;
}
.Sign-btn a .sign:hover {
    background-color: transparent;
    border: 2px solid var(--primary-color) ;
    color: #fff;
}
.Sign-btn .register:hover {
    background-color: var(--primary-color) ;
    border: none;
}
.Sign-btn .register {
    padding: 6px 19px;
    background-color: transparent;
    border: 2px solid var(--primary-color) ;
    height: 40px;
    border-radius: 50px;
    cursor: pointer;
    color: var(--primary-color) ;
    transition: all ease-in-out 0s;
}
.Sign-btn {
    display: flex !important;
    justify-content: space-between;
    margin-top: 20px;
}
.Sign-show {
    display: none !important;
}


/** ======================= logo image css ===================== */
.Brand a img {
    height: 40px !important;
    z-index: 999;
    object-fit: contain;
}
.Brand {
    float: left;
    line-height: 40px;
    margin-top: 16px;
    text-transform: uppercase;
    font-size: 1.4em;
}
.Brand a,
.Brand a:visited {
    color: #ffffff;
    text-decoration: none;
}
.mobile-logo {
    display: none;
}

/** ================== navbar dropdown css ================== */
.dropdown .dropdown-toggle{
    position: relative;
    z-index: 1;
    padding-left: 5px !important;
    line-height: 0px;
    border: none !important;
    background: transparent !important;
    --bs-btn-padding-x: 0rem !important;
}
.dropdown .dropdown-item{
    color: #fff !important;
    line-height: 30px;
}
.dropdown .dropdown-item a{
    line-height: 30px !important;
}
.dropdown .dropdown-item:hover{
    background: linear-gradient(to right,hsl(39deg 100% 48.25% / 43%),hsl(222deg 100% 58% / 0));
    border-left: 3px solid var(--primary-color) ;
    color: var(--primary-color) ;
}
.dropdown .dropdown-menu {
    --bs-dropdown-padding-y: 0rem; 
    transform: translate3d(-50px, 40px, 0px) !important;
    position: absolute;
    display: none;
    z-index: 1;
    padding-left: 0px !important;
    top: 75px;
    background: #171616e3;
    border-radius: 0px !important;
    box-shadow: 0 0 35px 0 rgba(56, 52, 52, 0.25);
}

/** ====================== navbar main css ================== */
  .menu-items li .active {
    color: var(--primary-color)  !important;
    border: none ;
  }
.navbar input[type="checkbox"],
.navbar .hamburger-lines {
    display: none;
}
.fa-icons {
    display: none;
}
.close-icon {
    display: none;
}
.navbar-container {
    display: flex;
    justify-content: space-between;
    height: 75px;
}
.menu-items {
    display: flex;
}
.logo {
    order: 1;
    font-size: 1.8rem;
}
.menu-items li,
button {
    list-style: none;
    margin-right: 15px;
    margin-left: 15px;
    font-size: 1.1rem;
    align-items: center;
}
.navbar a {
    color: #fff;
    text-decoration: none;
    line-height: 72px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    transition: color 0.3s ease-in-out;
}
.navbar > .container {
    display: grid !important;
    justify-content: unset !important;
}
.navbar a:hover {
    color: var(--primary-color) ;
}
.custom-btn {
    height: 40px !important;
    color: #fff !important;
    border-radius: 50px !important;
    line-height: 30px !important;
    padding: 0px 25px !important;
    background: transparent !important;
    cursor: pointer !important;
    border: 3px solid var(--primary-color)  !important;
    transition: all 0.3s ease !important;
    position: relative !important;
    display: inline-block !important;
    outline: none;
}
.btn-15 {
    background: transparent !important;
    border: 2px solid var(--primary-color)  !important;
    z-index: 1 !important;
}
.btn-15:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background-color: var(--primary-color) ;
    border-radius: 50px;
    transition: all 0.3s ease;
}
.btn-15:hover {
    color: #fff;
}
.btn-15:hover:after {
    left: 0;
    width: 100%;
}
.Navbar-dropdown li:hover{
background-color: #756c6c;
}
.dropdown button{
    font-size: 18px;
   }   
/** ===================== responsive css ======================== */
@media (max-width:1280px){
    .dropdown .dropdown-menu {
        transform: translate3d(0px, 40px, 0px) !important;
    }
}
@media (max-width: 1200px){
    .custom-btn {
        padding: 0px 15px !important;
    }    
.menu-items li, button {
    margin-left: 5px;
}
.menu-items {
    padding-left: 0px !important;
    }
}
@media (max-width:1200px){
    .Brand {
        margin-top: 21px;
    }
    .Brand a img {
        height: 32px !important;
    }    
    .dropdown button, .navbar a{
        font-size: 16px;
       }
    .mt-16 {
    margin-top: 16px !important;
}
.Sign-btn {
    margin-top: 18px;
}
}
@media (max-width:1100px){    
    .dropdown button, .navbar a{
        font-size: 15px;
       }
}
@media (max-width: 991px) {
    .navbar a {
        font-size: 15px;
        line-height: 65px;
    }
    .mt-16 {
        margin-top: 6px !important;
    }
    .custom-btn {
        padding: 0px 12px !important;
    }
    .menu-items li, button {
        margin-left: 5px;
        margin-right: 5px;
    }
    .Sign-btn a .sign {
        padding: 8px 0px !important;
        margin-right: 5px !important;
    }
    .Sign-btn .register {
        padding: 6px 0px !important;
    }
    .container {
        max-width: 100% !important;
    }
}
@media (max-width: 830px) {
    .main-nav .dropdown-toggle::after{
        margin-top: 15px !important;
    }
    .dropdown button{
        display: flex;
        justify-content: flex-start;
    }
    .mt-16{
        margin-top: 0px !important;
    }
    .dropdown .dropdown-toggle {
        line-height: 33px !important;
    }
    .menu-items li, button {
        align-items:unset !important;
    }
    .custom-btn {
        padding: 0px 15px !important;
        height: auto !important;
    }
    .dropdown .dropdown-item{
        border-bottom: 1px solid #756c6c !important;
    }
    .dropdown .dropdown-toggle{
        line-height: 40px;
    }
    .dropdown .dropdown-menu {
        position: unset !important;
        transform: translate3d(0px, 0px, 0px) !important;
        top: 0px !important;
        margin-bottom: -1px !important;
        background: #4f4444c7;
    }
    .dropdown{
        width: 100% !important;
    }
    .Sign-btn Button {
        padding: 0px 10px 0px 10px;
        font-size: 15px;
        margin: 0px 0px 0px 0px;
        background-color: transparent;
        border: 1px solid var(--primary-color) ;
        border-radius: 50px;
        cursor: pointer;
        color: var(--primary-color) ;
        transition: all ease-in-out 0s;
    }
    .Sign-btn {
        display: none !important;
    }
    .hamburger-lines .mobile-icon {
        font-size: 28px;
    }
    .Brand {
        display: none;
    }
    .Brand a img {
        margin-top: 0px;
    }
    .menu-items li,
    button {
        margin-left: 10px;
        margin-right: 10px;
    }
    .navbar-container ul li ul li a {
        line-height: 22px;
        color: #fff;
    }
    .Navbar-dropdown {
        position: static;
        background-color: transparent;
        line-height: 45px;
    }
    .Navbar-dropdown li {
        margin: 0px 0px 0px 0px !important;
    }
    .navbar-container ul li ul li:hover {
        background-color: var(--primary-color) ;
    }
    .navbar {
        background: #3d3535 !important;
    }
    .menu-items {
        order: 2;
        padding-left: 10px !important;        
        max-width: 344px;
    }
    .Sign-show {
        display: block !important;
        padding: 8px 0px;
    }
    .Sign-desktop {
        display: none;
    }
    .navbar-container input[type="checkbox"],
    .navbar-container .hamburger-lines {
        display: block;
    }
    .navbar-container {
        display: block;
        position: relative;
        height: 70px;
    }
    .navbar-container input[type="checkbox"] {
        position: absolute;
        display: block;
        height: 32px;
        width: 30px;
        top: 20px;
        left: 20px;
        z-index: 5;
        opacity: 0;
        cursor: pointer;
    }
    .navbar > .container {
        padding: 0px !important;
    }
    .navbar {
        padding: 0px !important;
    }
    .close-nav {
        z-index: 0;
        display: flex;
        flex-direction: unset !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .list-icons {
        margin: 15px 6px 0px 0px;
        font-size: 16px;
        display: flex !important;
    }
    .fa-icons {
        display: block;
    }
    .close-icon {
        font-size: 20px;
        display: block;
        position: absolute;
        top: 80px;
        height: 38px;
        padding: 7px 8px 7px 8px;
        width: 38px;
        border-radius: 50%;
        background-color: #fff;
        color: var(--primary-color) ;
        border: 1px solid var(--primary-color) ;
    }
    .navbar-container .hamburger-lines {
        display: block;
        width: 48px;
        padding: 10px 10px 10px 10px;
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 0;
        background-color: #756c6c;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .navbar-container .hamburger-lines .line {
        display: block;
        height: 4px;
        width: 100%;
        border-radius: 10px;
        background: #fff;
    }
    .navbar-container .hamburger-lines .line1 {
        transform-origin: 0% 0%;
        transition: transform 0.3s ease-in-out;
    }
    .navbar-container .hamburger-lines .line2 {
        transition: transform 0.1s ease-in-out;
    }
    .navbar-container .hamburger-lines .line3 {
        transform-origin: 0% 100%;
        transition: transform 0.3s ease-in-out;
    }
    .container {
        width: 100% !important;
    }
    .navbar .menu-items {
        padding-top: 70px;
        background-color: rgb(58 56 56 / 80%);
        height: 100vh !important;
        width: 100%;
        transform: translate(-150%);
        display: flex;
        flex-direction: column;
        padding: 50px 8px 0px 8px;
        transition: transform 0.5s ease-in-out;
        overflow: scroll;
    }
    .mobile-logo a img {
        height: auto;
        position: relative;
        top: 10px;
        object-fit: contain;
    }
    .navbar .menu-items li {
        margin-bottom: 0px;
        font-size: 15px;
        font-weight: 500;
        border-bottom: 1px solid #756c6c;
    }
    .navbar a {
        line-height: 45px;
    }
    button {
        width: fit-content;
        margin-left: 1rem;
    }
    .mobile-logo {
        display: block;
        position: absolute;
        right: 15px;
        font-size: 1.7rem;
    }
    .navbar-container input[type="checkbox"]:checked ~ .menu-items {
        transform: translateX(0);
    }
    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
        transform: rotate(45deg);
    }
    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
        transform: scaleY(0);
    }
    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
        transform: rotate(-45deg);
    }
}

@media (max-width:600px){    
    .dropdown .dropdown-menu {
        position: unset !important;
        transform: translate3d(0px, 0px, 0px) !important;
        top: 0px !important;
        margin-bottom: -1px !important;
        background: #4f4444c7;
    }
}

@media (max-width: 500px) {
    .navbar-container input[type="checkbox"]:checked ~ .logo {
        display: none;
    }
}
